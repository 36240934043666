import React from 'react'

const Footer = (props) => {
    const url = "https://mihclothingco.com/19240-pro-shop"

    // const handleClick = () =>{
    //     window.location.replace(url)
    // }

    

    return (
        <footer id="pic-footer"
       >
            <a href={url} target="__blank">Back To Shop</a>
            </footer>
    )
}

export default Footer